<template>
  <v-widget>
    <template #content>
      <v-row class="d-flex flex-row justify-space-between pt-2">
        <v-col cols="12" md="6" lg="4" class="">
          <span class="text-h6 font-weight-bold black--text">
            Overview
          </span>
        </v-col>
        <v-col cols="12" md="6" lg="4" class="">
          <v-text-field
            :label="$t('portal_search')"
            v-model="search"
            clearable
            @keydown.esc="search = null"
            append-icon="mdi-magnify"
            dense
          />
        </v-col>
      </v-row>
      <base-table
        :headers="headers"
        :height="handleHeightTable"
        :items="customItems"
        :search="search"
        disable-pagination
        hide-default-footer
        dense
      >
        <template #item="{ item }">
          <tr role="button" @click="handleClick(item)" class="font-weight-bold">
            <td>
              {{ item.code }}
            </td>
            <td>{{ item.fullName }}</td>
            <td>{{ item.accountNumber }}</td>
            <td>
              {{ item.type }}
            </td>
            <td>
              {{ item.date | date }}
            </td>
          </tr>
        </template>
      </base-table>
    </template>
  </v-widget>
</template>

<script>
import BaseTable from '@/components/commons/BaseTable'
import VWidget from '@/components/commons/VWidget'
import { mapState } from 'vuex'

export default {
  components: { BaseTable, VWidget },
  props: {
    items: {
      type: Array
    }
  },
  computed: {
    ...mapState('language', ['languages', 'idiom']),
    customItems() {
      const modifiedItems = this.items.map(item => {
        return {...item, fullName: item.firstname + ' ' + item.lastname}
      })
      return modifiedItems
    },
    headers() {
      return [
        {
          text: this.$t('portal_account_code'),
          value: 'code',
          align: 'start',
          width: this.$vuetify.breakpoint.smAndDown ? '100' : '200',
          cellClass: 'font-weight-bold'
        },
        {
          text: this.$t('portal_full_name'),
          value: 'fullName',
          align: 'start',
          cellClass: 'font-weight-bold'
        },
        {
          text: this.$t('portal_account_number'),
          value: 'accountNumber',
          align: 'start',
          cellClass: 'font-weight-bold'
        },
        {
          text: this.$t('portal_account_type'),
          value: 'type',
          align: 'start',
          cellClass: 'font-weight-bold'
        },
        {
          text: this.$t('portal_date'),
          value: 'date',
          align: 'start',
          cellClass: 'font-weight-bold'
        }
      ]
    },
    handleHeightTable() {
      const viewportHeight = window.innerHeight
      switch (true) {
        case viewportHeight < 640:
          return `55vh`
        case viewportHeight < 700:
          return `65vh`
        case viewportHeight < 800:
          return `60vh`
        case viewportHeight < 900:
          return `60vh`
        case viewportHeight < 1000:
          return `60vh`
        case viewportHeight < 1100:
          return `60vh`
        case viewportHeight < 1500:
          return `60vh`
        case viewportHeight < 1800:
          return `70vh`
        case viewportHeight < 2200:
          return `77vh`
        default:
          return `80vh`
      }
    },
    accountCodeHeaderHandler() {
      return '100'
    }
  },
  data() {
    return {
      search: ''
    }
  },
  methods: {
    handleClick(item) {
      this.$emit('click', item)
    }
  }
}
</script>

<style></style>
